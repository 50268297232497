if ($('.vehicles-row #vehiclesList').length) {
    var sessionName = $('.vehicles-row #sessionId').val();
}
var vehiclesLoading = false;
var vehiclesCountPerPage = 8;
var vehiclesLimit = vehiclesCountPerPage;
var vehiclesOffset = 0;
var vehiclesElementCount = 0;
var vehiclesScrollLoadEnabled = false;

$(document).ready(function(){
   if ($('.vehicles-row #vehiclesList').length) {
       var filterType = $('.vehicles-row #filterType').val();
       if (filterType == 'mmc-dealer') {
           var member = $('.vehicles-row #memberId').val();
           var initVars = {make: 55, vehtype: 10, filterType: filterType, member: member};
       } else if (filterType == 'mmc-model') {
           var modelId = $('.vehicles-row #modelId').val();
           var initVars = {make: 55, vehtype: 10, filterType: filterType, group: 49, model: modelId};
       } else {
           var initVars = {make: 55, vehtype: 10, group: 49, filterType: filterType};
       }
       var initUI = {singDropOpt: [], multiDropOpt: [], graphOpt: [], filters: []};
       if (sessionStorage.getItem('mmFilter_' + sessionName) === null) {
           sessionStorage.setItem('mmFilter_' + sessionName, JSON.stringify(initVars));
           sessionStorage.setItem('mmUIFilter_' + sessionName, JSON.stringify(initUI));
       }

       redrawUI();
   }

    // range slider init
    if ($(".btn-group-range").length) {
        $(".btn-group-range").each(function() {
            let rangeSlider = $(this).find('.js-range-slider');
            // initialization of range slider
            $.HSCore.components.HSRangeSlider.init(rangeSlider);
        });
    }

    // append second filter row into first
    if ($(window).width() < 1200) {
        $('.first-filter-row .first').prependTo('.second-filter-row');
        $('.more-filter-desktop').hide();
        $('.more-filter-mobile').show();
    } else {
        $('.second-filter-row .first').prependTo('.first-filter-row');
        $('.more-filter-mobile').hide();
        $('.more-filter-desktop').show();
    }

    // Show more Car Filter
    $('#show-more-filter').on('click',function() {
        $('.second-filter-row').toggleClass("hidden");
        if ($(window).width() < 1200) {
            $(this).find('.more-filter-mobile').toggle();
            $('.reset-filter').toggle();
        } else {
            $(this).find('.more-filter-desktop').toggle();
        }
        $(this).find('.less-filter').toggle();

        $('html,body').animate({
            scrollTop: $(".angebote-filter").offset().top
        }, 'slow');
    });

    // Filter function
    var filters = [];

    // single selection
    $('#cars-filter .dropdown-menu:not(.multi-select) a').on('click', function(event) {
        var $target = $( event.currentTarget ),
        val = $target.attr('data-value'),
        key = $target.attr('data-key'),
        filterVal = $target.parents().eq(2).find('.filter-val'),
        txt = $target.find('h5').text(),
        $inp = $target.find( 'input' ),
        dropdownVal = $target.parent().find('.dropdown-item.active').attr('data-value'),
        idx,
        curKey = val.split('-');
        var sessionStates = getSearchSessionState();
        var locdataVars = sessionStates[0];
        var locsingDropOpt = sessionStates[1];
        var locmultiDropOpt = sessionStates[2];
        var locgraphOpt = sessionStates[3];
        var locfilters = sessionStates[4];
        // delete locdataVars[curKey[0]];
        //check key is added to sigDrop list
        if ((jQuery.inArray(val, locfilters)) != -1) {
            delete locdataVars[curKey[0]];
            var idx = locsingDropOpt.indexOf(curKey[0]);
            locsingDropOpt.splice(idx, 1);
            idx = locfilters.indexOf(val);
            locfilters.splice(idx, 1);
        } else {
            //not in array
            if ((jQuery.inArray(curKey[0], locsingDropOpt)) == -1) {
                locsingDropOpt.push(curKey[0]);
            } else {
                var idx = locfilters.indexOf(curKey[0] + '-' + locdataVars[curKey[0]]);
                locfilters.splice(idx, 1);
            }
            locfilters.push(val);
            $.each(locfilters, function (index, value) {
                var args = value.split('-');
                if (args[0] == curKey[0]) {
                    locdataVars[args[0]] = args[1];
                }
            });
        }

//      if ( ( idx = locfilters.indexOf( dropdownVal ) ) > -1 ) {
//          setTimeout( function() { $inp.prop( 'checked', false ) }, 0);
//      }
//      locfilters.push( val );

        setTimeout( function() { $inp.prop( 'checked', true ) }, 0);

        if ($target.hasClass('active')) {
            $target.removeClass('active');
        } else {
            $target.parent().find('.dropdown-item').removeClass('active');
            $target.toggleClass('active');
        }

        $( event.target ).blur();

//      $.each(locfilters, function (index, value) {
//          var args = value.split('-');
//          if (args[0] == curKey[0]) {
//              locdataVars[args[0]] = args[1];
//          }
//      });

        // close dropdown-menu
        $target.parents().find('.dropdown-menu').removeClass('show');

        // change filter val text
        if (filterVal.find('.txt').length !==0 ) {
            if (filterVal.find('.txt').hasClass(val)) {
                filterVal.find('.txt').remove();
                filterVal.find('.initial').show();
            } else {
                filterVal.find('.txt').remove();
                filterVal.append('<span class="txt '+val+'">'+txt+'</span>');
            }
        } else {
            filterVal.append('<span class="txt '+val+'">'+txt+'</span>');
            filterVal.find('.initial').hide();
        }
        setSearchSessionState(locdataVars, locsingDropOpt, locmultiDropOpt, locgraphOpt, locfilters);
        loadFilteredVehicles();
        return false;

    });

    //multi selection
    $('#cars-filter .dropdown-menu.multi-select a').on('click', function(event) {

        var $target = $( event.currentTarget ),
        val = $target.attr('data-value'),
        filterVal = $target.parents().eq(2).find('.filter-val'),
        txt = $target.find('h5').text(),
        $inp = $target.find( 'input' ),
        idx,
        curKey = val.split('-');
        var sessionStates = getSearchSessionState();
        var locdataVars = sessionStates[0];
        var locsingDropOpt = sessionStates[1];
        var locmultiDropOpt = sessionStates[2];
        var locgraphOpt = sessionStates[3];
        var locfilters = sessionStates[4];
        //delete locdataVars[curKey[0]];
        //remove the multi selected options from locdataVars
        console.log(locdataVars);
        console.log('----');
        console.log(curKey);
        console.log('----');
        if(locdataVars[curKey[0]] === undefined){
            locdataVars[curKey[0]] = curKey[1];
            locmultiDropOpt.push(curKey[0]);
            console.log('OK');
        }
        else {
            var fileteredmodels = (locdataVars[curKey[0]]).split(',');
            var idx = jQuery.inArray(curKey[1], fileteredmodels);
            if (idx == -1) {
                fileteredmodels.push(curKey[1]);
                console.log('1OK');
            } else {
                fileteredmodels.splice(idx, 1);
                console.log('2OK');
            }
            if (fileteredmodels.length > 0) {
                locdataVars[curKey[0]] = (fileteredmodels.toString());
            } else
            {
                delete locdataVars[curKey[0]];
                locmultiDropOpt = jQuery.grep(locmultiDropOpt, function (value) {
                    return value != curKey[0];
                });
            }
        }
        console.log(locdataVars);
        console.log('----');
        console.log(locmultiDropOpt);

        $target.toggleClass('active');

        if ( ( idx = locfilters.indexOf( val ) ) > -1 ) {
        locfilters.splice( idx, 1 );
        setTimeout( function() { $inp.prop( 'checked', false ) }, 0);
        } else {
        locfilters.push( val );
        setTimeout( function() { $inp.prop( 'checked', true ) }, 0);
        }
        $( event.target ).blur();

        // change multiple filter val text
        if (filterVal.find('.txt').length !==0 && filterVal.find('.txt').hasClass(val)) {
            $('.'+val).remove();
            if (filterVal.find('.txt').length === 0) {
                filterVal.find('.initial').show();
            }
        } else {
            filterVal.append('<span class="txt '+val+'">'+txt+'</span>');
            filterVal.find('.initial').hide();
        }

        setSearchSessionState(locdataVars, locsingDropOpt, locmultiDropOpt, locgraphOpt, locfilters);
        // updateFilterDisplay(1,filterVal, val, txt);
        loadFilteredVehicles();
        return false;
    });

    // search field
    var wto;
    $('#cars-filter .bttn-search').on('input', function(event) {
        var $target = event.currentTarget,
        val = $target.value
        let search = $(this).attr("data-name");
        let oldSearch = filters.find(el=>el.hasOwnProperty(search));

        if (val.length > 2) {
            if (oldSearch) {
                //remove old val
                filters.map(function(item) {
                    delete item[search];
                    return item;
                });
                //add new val
                filters.push(  {
                    [search]: val
                });
                // enable "Umkreis"
                $('.bttn-umkreis').prop("disabled", false);
            } else {
                //add new search val
                filters.push(  {
                    [search]: val
                });
                $('.bttn-umkreis').prop("disabled", false);
            }
        } else {
            filters.map(function(item) {
                delete item[search];
                return item;
            });
            $('.bttn-umkreis').prop("disabled", true);
            // TO DO: remove umkreis val from filters
        }
        //remove empty objects
        filters = filters.filter(value => Object.keys(value).length !== 0);

        clearTimeout(wto);
        wto = setTimeout(function() {
            console.log(filters);
        }, 1000);
    });

    var debounce = null;
    // add price range to filters
    $('#cars-filter .js-range-slider').on('change',function(event){

        var $target = event.currentTarget,
        val = $target.value
        let range = $(this).attr("data-name");
        let key = $(this).attr("data-key");
        let oldRange = filters.find(el=>el.hasOwnProperty(range));
        let _this = $(this);

        if (oldRange) {
            //remove old price range
            filters.map(function(item) {
            delete item[range];
            return item;
            });
            //add new price range
            filters.push(  {
                [range]: val
            });
        } else {
            //add new price range
            filters.push(  {
                [range]: val
            });
        }

        var rangeVal = val.split(';');
        var sessionStates = getSearchSessionState();
        var locdataVars = sessionStates[0];
        var locsingDropOpt = sessionStates[1];
        var locmultiDropOpt = sessionStates[2];
        var locgraphOpt = sessionStates[3];
        var locfilters = sessionStates[4];
        locdataVars[key + 'from'] = rangeVal[0];
        locdataVars[key + 'to'] = rangeVal[1];
        //check key is added to sigDrop list
        var found = jQuery.inArray(key, locgraphOpt);
        if (found == -1) {
            // Element was not found, add it.
            locgraphOpt.push(key);
        }
        //remove empty objects
        filters = filters.filter(value => Object.keys(value).length !== 0);

        clearTimeout(debounce);
        debounce = setTimeout(function(){
            if (_this.attr("data-reset") == 'false') {
                setSearchSessionState(locdataVars, locsingDropOpt, locmultiDropOpt, locgraphOpt, locfilters);
                loadFilteredVehicles();
            }
            _this.attr("data-reset", "false");
        }, 1000);
    });

    // reset filter
    $('#cars-filter #reset-filter').on('click', function() {
        // reset dropdown items
        $('.btn-group .dropdown-menu .dropdown-item').removeClass('active');
        // reset checkboxes
        $('.dropdown-item .form-check-input').prop('fchecked', false);
        // reset filter val
        $('.filter-val').find('.txt').remove();
        $('.filter-val').find('.initial').show();
        // reset search field
        $('.bttn-search').val('');
        // reset price ranges
        var slider = $(".js-range-slider");
        slider.each(function( i, el ) {
            let sliderData = $(el).data('ionRangeSlider');
            if (sliderData) {
                $(el).attr("data-reset", "true");
                sliderData.reset();
            }
        });
        // reset filter array
        filters = [];
        var initUI = {singDropOpt:[], multiDropOpt:[], graphOpt:[], filters:[]};
        var filterType = $('.vehicles-row #filterType').val();
        if (filterType == 'mmc-dealer') {
            var member = $('.vehicles-row #memberId').val();
            var initVars = {make: 55, vehtype: 10, filterType: filterType, member: member};
        } else if (filterType == 'mmc-model') {
            var modelId = $('.vehicles-row #modelId').val();
            var initVars = {make: 55, vehtype: 10, filterType: filterType, group: 49, model: modelId};
        } else {
            var initVars = {make: 55, vehtype: 10, group: 49, filterType: filterType};
        }
        setSearchSessionState(initVars, initUI['singDropOpt'], initUI['multiDropOpt'], initUI['graphOpt'], initUI['filters']);
        loadFilteredVehicles()
        console.log(filters);
    });

    // sort Cars Desktop
    $('.cars-filter-info .btn-group-sort a').on('click', function(event) {
        var sessionStates = getSearchSessionState();
        var locdataVars = sessionStates[0];
        var locsingDropOpt = sessionStates[1];
        var locmultiDropOpt = sessionStates[2];
        var locgraphOpt = sessionStates[3];
        var locfilters = sessionStates[4];
        var $target = $( event.currentTarget );
        var val = $target.attr('data-value');
        var args = val.split('-');
        if (args[1] == 'clear'){
            delete locdataVars[args[0]];
        } else {
            locdataVars[args[0]] = args[1];
        }
        setSearchSessionState(locdataVars, locsingDropOpt, locmultiDropOpt, locgraphOpt, locfilters);
        loadFilteredVehicles();
    });

    // sort Cars Mobile
    function sortCars(val) {
        console.log(val);
    }

    //------------------------------------------------------------------------------
    // add padding above angebote filter if first section on page
    //------------------------------------------------------------------------------
    var angebote_filter_section = $("section.angebote-filter");
    var headerheight = $(".header").outerHeight();

    if(angebote_filter_section.is(':first-child')){
        angebote_filter_section.css('padding-top', headerheight+"px");
    };


     //------------------------------------------------------------------------------
    // Change Text for car sorting dropdown menu
    //------------------------------------------------------------------------------
    $('.cars-filter-info .dropdown-menu:not(.multi-select) a').on('click', function(event) {
        var $target = $( event.currentTarget ),
        val = $target.attr('data-value'),
        sortVal = $target.parents().eq(2).find('.sort-val'),
        txt = $target.find('h5').text();

        // close dropdown-menu
        $target.parents().find('.dropdown-menu').removeClass('show');

        // change filter val text
        if (sortVal.find('.txt').length !==0 ) {
            if (sortVal.find('.txt').hasClass(val)) {
                sortVal.find('.txt').remove();
                sortVal.find('.initial').show();
            } else {
                sortVal.find('.txt').remove();
                sortVal.append('<span class="txt '+val+'">'+txt+'</span>');
            }
        } else {
            sortVal.append('<span class="txt '+val+'">'+txt+'</span>');
            sortVal.find('.initial').hide();
        }
        return false;
    });


});

$(window).on('resize', function () {
    // append second filter row into first
    if ($(window).width() < 1200) {
        $('.first-filter-row .first').prependTo('.second-filter-row');
        $('.more-filter-desktop').hide();
        $('.more-filter-mobile').show();
    } else {
        $('.second-filter-row .first').prependTo('.first-filter-row');
        $('.more-filter-mobile').hide();
        $('.more-filter-desktop').show();
    }

    //------------------------------------------------------------------------------
    // add padding above angebote filter if first section on page
    //------------------------------------------------------------------------------
    var angebote_filter_section = $("section.angebote-filter");
    var headerheight = $(".header").outerHeight();
    if(angebote_filter_section.is(':first-child')){
        angebote_filter_section.css('padding-top', headerheight+"px");
    };
});


function loadFilteredVehicles(append) {
    var sessionStates = getSearchSessionState();
    var locdataVars = sessionStates[0];
    var locsingDropOpt = sessionStates[1];
    var locmultiDropOpt = sessionStates[2];
    var locgraphOpt = sessionStates[3];
    var locfilters = sessionStates[4];
    var urlParams = locdataVars;
    var str = jQuery.param( urlParams );
    $.get('/?eID=mmt_vehicles&'+str, function(data){
        $('.vehicles-row #vehiclesList').html('');
        $('.vehicles-row #vehiclesList').append(data);
        $("span.cars-number").html($('.vehicles-row #vehiclesCount').val());
    });
}

function getSearchSessionState(){
   let locdataVars = $.parseJSON(sessionStorage.getItem('mmFilter_'+sessionName));
   let locUIVars = $.parseJSON(sessionStorage.getItem('mmUIFilter_'+sessionName));
   let locsingDropOpt = locUIVars['singDropOpt'];
   let locmultiDropOpt = locUIVars['multiDropOpt'];
   let locgraphOpt = locUIVars['graphOpt'];
   let locfilters = locUIVars['filters'];
   return [locdataVars, locsingDropOpt, locmultiDropOpt, locgraphOpt, locfilters];
}

function setSearchSessionState(locdataVars, locsingDropOpt, locmultiDropOpt, locgraphOpt, locfilters){
   sessionStorage.setItem('mmFilter_'+sessionName, JSON.stringify(locdataVars));
   var locinitUI = {singDropOpt:[], multiDropOpt:[], graphOpt:[], filters:[]};
   locinitUI['singDropOpt'] = locsingDropOpt;
   locinitUI['multiDropOpt'] = locmultiDropOpt;
   locinitUI['graphOpt'] = locgraphOpt;
   locinitUI['filters'] = locfilters;
   sessionStorage.setItem('mmUIFilter_'+sessionName, JSON.stringify(locinitUI));
}

// function to re-construct ui based on the saved session
function redrawUI() {
    var sessionStates = getSearchSessionState();
    var locdataVars = sessionStates[0];
    var locsingDropOpt = sessionStates[1];
    var locmultiDropOpt = sessionStates[2];
    var locgraphOpt = sessionStates[3];
    var locfilters = sessionStates[4];
    $.each(locdataVars, function (key, value) {
       console.log("OK1");
       //re-construct the range keys as from/to is added in autoscout filter
       rangekey = key.replace('from', '');
       if ((jQuery.inArray(key, locsingDropOpt)) > -1) {
            $(".btn-group .dropdown-menu:not(.multi-select) .dropdown-item[data-value='" + key + '-' + value + "']").addClass('active');
            txt =$(".btn-group .dropdown-menu:not(.multi-select) a.dropdown-item[data-value='" + key + '-' + value + "']").find('h5').text();
            updateFilterDisplay(0, key, value, txt);
       } else if ((jQuery.inArray(key, locmultiDropOpt)) > -1) {

       console.log("OK3");
            var items = value.split(',');
            var txt;
       console.log(items);
            $.each(items, function (keyval, item) {
                $(".btn-group .dropdown-menu.multi-select a.dropdown-item[data-value='" + key + '-' + item + "']").addClass('active');
                $(".dropdown-item[data-value='" + key + '-' + item + "'] .form-check-input").prop('checked', true);
                txt =$(".btn-group .dropdown-menu.multi-select a.dropdown-item[data-value='" + key + '-' + item + "']").find('h5').text();
                updateFilterDisplay(1, key, item,txt);
            });
       } else if ((jQuery.inArray(rangekey, locgraphOpt)) > -1) {
            let rangeSlider = $(".js-range-slider[data-key='" + rangekey + "']");
            let barChart = $(".js-bar-chart[data-key='" + rangekey + "']");
            let graphForegroundTarget = $(rangeSlider).data('foreground-target');
            if (barChart.length > 0) {
                $.when($.HSCore.components.HSRangeSlider.init(rangeSlider)).then(
                    setTimeout(function () {
                            $(rangeSlider).attr("data-init", "true");
                        // initialization of chartist bar chart
                        $.HSCore.components.HSChartistBarChart.init(barChart);
                        // set back fgbar
                        $(graphForegroundTarget + '> *').css({
                            width: $(graphForegroundTarget).parent().width()
                        });
                    }, 400)
                );
            } else {
                // initialization of range slider
                $.HSCore.components.HSRangeSlider.init(rangeSlider);
            }
            let sliderhandle = $(".js-range-slider[data-key='" + rangekey + "']");
            let sliderData = sliderhandle.data('ionRangeSlider');
            sliderData.update({
                min: sliderhandle.attr('data-min'),
                max: sliderhandle.attr('data-max'),
                from: locdataVars[rangekey + 'from'],
                to: locdataVars[rangekey + 'to']//your new value
            });
            sliderData.reset();
        }
    });

   loadFilteredVehicles();
   return false;
}

function updateFilterDisplay(type, key, val,txt){
   if (type == 0) {
       var filterVal = $('button.bttn-filter').find('#filter-val-' + key);
       filterVal.append('<span class="txt ' + key + '-' + val + '">' + txt + '</span>');
       filterVal.find('.initial').hide();
   } else if (type == 1) {
       var filterVal = $('button.bttn-filter').find('#filter-val-' + key);
       filterVal.append('<span class="txt ' + key + '-' + val + '">' + txt + '</span>');
       filterVal.find('.initial').hide();
   }
}

function downloadFile(file) {
  $.ajax({
       url: file,
       method: 'GET',
       xhrFields: {
           responseType: 'blob',
           withCredentials: false
       },
       success: function (data) {
           var a = document.createElement('a');
           var url = window.URL.createObjectURL(data);
           a.href = url;
           a.download = 'name.jpeg';
           document.body.append(a);
           a.click();
           a.remove();
           window.URL.revokeObjectURL(url);
       }
   });
}